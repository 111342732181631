import { defineStore } from 'pinia'
import { useCourseStore } from '~/stores/course'
import { useAlertStore } from '~/stores/alert'
import { addDays } from 'date-fns'

import ShortlistEntry = models.stores.shortlist.ShortlistEntry

interface State {
  items: ShortlistEntry[]
  expiryDate: Date
}

export const useShortlistStore = defineStore('shortlist', {
  state: (): State => ({
    items: [],
    expiryDate: new Date()
  }),

  getters: {
    isExpired: (state) => new Date() > new Date(state.expiryDate),
    getByOfferingId: (state) => (offeringId: string) => {
      return state.items?.find((item) => item.id === offeringId)
    }
  },

  actions: {
    async add(payload: ShortlistEntry) {
      // add to shortlist
      this.items.push(payload)

      const courseStore = useCourseStore()
      const course = await courseStore.fetchOne(payload.courseId)
      const title = (course?.title as string) || ''

      // get course name for alert
      const { delivery } = payload

      // show alert
      const alertStore = useAlertStore()
      alertStore.set({
        msg: 'Offering added to shortlist',
        id: title,
        delivery,
        icon: 'IconFavouriteFilled',
        iconColour: 'ochre-400'
      })
    },
    remove({ id, courseId, delivery }: ShortlistEntry) {
      // remove from shortlist
      const index = this.items.findIndex((item) => item.id === id)
      this.items.splice(index, 1)

      // get course name for alert
      const courseStore = useCourseStore()
      const title = courseStore.getOne(courseId).title

      const alertStore = useAlertStore()
      alertStore.set({
        msg: 'Offering removed from shortlist',
        id: title,
        delivery,
        icon: 'IconFavourite'
      })
    },
    reset() {
      this.items = []
    },
    setExpiryDate() {
      // set new expiry date 1 day from now
      this.expiryDate = addDays(new Date(), 1)
    }
  },
  persist: { storage: persistedState.localStorage }
})
